app.factory('webConfigService', ['$http','$q', function($http, $q) {

    var webServiceFactory = {};

    var _urlRoot = undefined;

    var _getConfig = function () {
        var deferred = $q.defer();
        if (_urlRoot === undefined) {
            $http.get('api/webconfig').success(function (data) {
                console.log('web config data:', data);
                _urlRoot = data.urlRoot;
                deferred.resolve(_urlRoot);
            }).error( function(error) {
                console.log(error);
                deferred.reject(error);
            });
        } else {
            deferred.resolve(_urlRoot);
        }
        return deferred.promise;
    };

    webServiceFactory.getConfig = _getConfig;
    webServiceFactory.urlRoot = _urlRoot;

    return webServiceFactory;
}]);