'use strict';
app.controller('indexController', ['$scope', '$state', 'authService', function ($scope, $state, authService) {

    $scope.openClaims= function() {
        $state.go('claims', null, {reload:true});
    }

    $scope.logOut = function () {
        authService.logOut();
        $state.go('home');
    }

    $scope.authentication = authService.authentication;

    $scope.resetImpersonation = function () {
        console.log('reset impersonation');
        authService.resetImpersonateUser().then(function (data) {
            console.log('authentication', authService.authentication);
            console.log('goto claims');
            $state.go('claims',null, {reload: true});
        }, function(error) {
            console.log(error);
            $state.go('login');
        });
    };

}]);