app.controller('claimEditController', [
    '$scope',
    '$state',
    '$stateParams',
    'claimEditService',
    'ngDialog',
    'claim',
    '$rootScope',
    '$q',
    '$location',
    'authService',
    'errorService',
    function($scope, $state, $stateParams, claimEditService, ngDialog, claim, $rootScope, $q, $location, authService, errorService) {

        var _abandonUnloadedFiles = function() {
            var deferred = $q.defer();
            if (claimEditService.unassignedFiles() && $scope.status != 'Abandoned') {
                ngDialog.openConfirm({
                    template: 'app/views/dialogs/abandonFiles.html',
                    scope: $scope,
                }).then(function (yes) {
                    deferred.resolve(yes);
                }, function (no) {
                    deferred.reject(no);
                });
            } else {
                deferred.resolve(true);
            }
            return deferred.promise;
        }

        var _processNewMessage = function (cl) {
            if (cl.newMessage.messageHeading !== '' || cl.newMessage.messageText !== '') {
                // fix up references needs refactoring
                if (cl.newMessage.recipiantEmail === $scope.supplierEmail) {
                    cl.newMessage.recipiantCompanyId = cl.supplierId;
                    cl.newMessage.recipiantId = cl.supplierUserId;
                } else if (cl.newMessage.recipiantEmail === $scope.customerEmail) {
                    cl.newMessage.recipiantCompanyId = cl.customerId;
                    cl.newMessage.recipiantId = cl.customerUserId;
                } else if (cl.newMessage.recipiantEmail === $scope.brokerEmail) {
                    cl.newMessage.recipiantCompanyId = cl.brokerId;
                    cl.newMessage.recipiantId = cl.brokerUserId;
                }
                if (cl.newMessage.senderEmail === $scope.supplierEmail) {
                    cl.newMessage.senderCompanyId = cl.supplierId;
                    cl.newMessage.senderId = cl.supplierUserId;
                } else if (cl.newMessage.senderEmail === $scope.customerEmail) {
                    cl.newMessage.senderCompanyId = cl.customerId;
                    cl.newMessage.senderId = cl.customerUserId;
                } else if (cl.newMessage.senderEmail === $scope.brokerEmail) {
                    cl.newMessage.senderCompanyId = cl.brokerId;
                    cl.newMessage.senderId = cl.brokerUserId;
                }
                console.log('message from:', cl.newMessage.senderId, 'to:', cl.newMessage.recipiantId);
                cl.messages = [claim.newMessage];
                cl.newMessage = {};
            } else {
                cl.messages = [];
                cl.newMessage = {};
            }
        };

        var _updateDocumentTypes = function() {
            $scope.claim.documents.length = 0;
            angular.forEach($scope.documentFiles, function(file) {
                if (file.typeChanged) {
                    $scope.claim.documents.push(
                    {
                        id: file.id,
                        documentType: file.purpose.value,
                    });
                }
            });
        }

        $scope.disableAllButtons = false;

        $scope.saveClaim = function (status) {
            console.log('Customer ref', $scope.claim.customerRef);
            $scope.disableAllButtons = true;
            $scope.claim.claimLocation = $location.absUrl();
            console.log('claim url', $location.absUrl());
            if (claimEditService.isCustomer && $scope.claim.customerUserId === 0) {
                $scope.claim.customerUserId = claimEditService.userId;
                $scope.claim.customerName = claimEditService.userEmail;
            }
            _processNewMessage($scope.claim);
            _updateDocumentTypes();
            _abandonUnloadedFiles()
                .then(function() {return claimEditService.uploadDocuments(); })
                .then(function() {return claimEditService.saveClaim($scope.claim, status); })
                .then(function () { $state.go('claims'); },
                function (error) {
                     errorService.showError(error);
                });
            console.log('drop through');
        };

        $scope.updateClaim = function() {
            $scope.saveClaim($scope.claim.status);
        };
        $scope.saveDraft = function () {
            $scope.saveClaim('Draft');
        };
        $scope.submitClaim = function() {
            $scope.saveClaim('New claim');
        };
        $scope.forwardClaim = function () {
            $scope.saveClaim('Notified');
        };
        $scope.inviteSupInvoice = function() {
            $scope.saveClaim('Inv. pending');
        };
        $scope.disputeInvoice = function() {
            $scope.saveClaim('Disputed');
        };
        $scope.authorisePayment = function() {
            $scope.saveClaim('Pmnt. auth.');
        };

        $scope.remittanceRcved = function() {
            $scope.saveClaim('Remittance rcved');
        };

        $scope.abandonClaim = function () {
            $scope.claim.abandonnedBy = authService.authentication.companyId;
            $scope.saveClaim('Abandoned');
        };

        $scope.tryAbandonClaim = function() {
            ngDialog.openConfirm({
                template: 'app/views/Dialogs/abandonClaim.html',
                scope: $scope,
            }).then(function(yes) {
                $scope.abandonClaim();
            }, function(no) {
                $scope.claim.abandonnedReason = '';
            });
        };


        $scope.close = function() {
            if (claimEditService.claimIsDirty()) {
                ngDialog.openConfirm({
                    template: 'app/views/dialogs/abandonDirtyForm.html',
                    scope: $scope,
                }).then(function (success) {
                    $state.go('claims');
                }, function(no) {
                });
            } else {
                $state.go('claims');
            }
        };

        $scope.sendCustomerEmail = function() {
            ngDialog.openConfirm({
                template: 'app/views/dialogs/claimEmail.html',
                scope: $scope
            }).then(function(yes) {
                if ($scope.claim.status === 'New claim' ||
                    $scope.claim.status === 'Draft') {
                    $scope.claim.status = 'Notified';
                }
                $scope.claim.sendClaimEmail = true;
                $scope.updateClaim();
            }, function(no) {
                $scope.claim.additionalEMail = '';
                $scope.claim.notificationHeading = '';
                $scope.claim.additionalHeading = '';
                $scope.claim.messagePreamble = '';
            });
        };

        claimEditService.setClaim(claim);

        claimEditService.setupScope($scope).then(function (data) {
            if (authService.authentication.isAuthCustomer &&
                ($scope.claim.status === 'Notified' ||
                $scope.claim.status === 'Draft' ||
                $scope.claim.status === 'New claim')) {
                $scope.claim.claimLocation = $location.absUrl();
                claimEditService.saveClaim($scope.claim, 'Examined');
            }

            if ($scope.flags.isAbandoned) {
                if ($scope.claim.abandonnedBy === $scope.claim.supplierId) {
                    $scope.abandonedBy = $scope.claim.supplierName;
                } else if ($scope.claim.abandonnedBy === $scope.claim.brokerId) {
                    $scope.abandonedBy = $scope.claim.brokerName;
                }
                $scope.abandonReason = $scope.claim.abandonnedReason ? $scope.claim.abandonnedReason : 'no reason given';
            }
        }, function (error) {

        });

//        $rootScope.$on('$stateChangeStart', function(e, toState, toParams, fromState, fromParams) {
//            if (toState.name.indexOf('claimEdit') === 0) {
//            } else {
//                _abandonClaim()
//                    .then(function() {
//                        console.log('abandon');
//                        $state.go(toState.name, toParams, { notify: false });
//                    }, function() {
//                        console.log('stay');
//                        e.preventDefault();
//                    });
//
//            }
//        });
    }
]);