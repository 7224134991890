app.factory('identificationService', [
    '$injector', function($injector) {

        var identificationServiceFactory = {};

        var _request = function (config) {
            config.headers = config.headers || {};
            console.log('config', config);
            if (config.url.toLowerCase().indexOf('api/') > -1) {
                var as = $injector.get('authService');
                var id = as.identification();
                var ident = { identification: id };
                config.headers['ims-identification'] = id;
            }
            return config;
        };

        identificationServiceFactory.request = _request;

        return identificationServiceFactory;
    }
]);