app.factory('supplierListService', ['$http', '$q', function ($http, $q) {

    var supplierListServiceFactory = {};

    var _getSupplierlist = function () {

        var deferred = $q.defer();
        var url = 'api/suppliers';

        $http.get(url).success(function (data) {
            deferred.resolve(data);
        }).error(function (error) {
            deferred.reject(error);
        });
        return deferred.promise;
    }

    var _deleteSupplier = function (supplier) {
        var url = 'api/suppliers/' + supplier.id;
        var deferred = $q.defer();
        $http.delete(url).success(function (data) {
            deferred.resolve(data);
        }).error(function (error) {
            deferred.reject(error);
        });
        return deferred.promise;
    };

    supplierListServiceFactory.getSupplierList = _getSupplierlist;
    supplierListServiceFactory.deleteSupplier = _deleteSupplier;

    return supplierListServiceFactory;
}])