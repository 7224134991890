app.controller('messagesController', [
    '$scope', 'claimEditService', function($scope, claimEditService) {

        claimEditService.setupScope($scope);

        $scope.recipiants = [];

        if ($scope.isBroker) {
            $scope.claim.newMessage.senderEmail = $scope.claim.brokerEmail;
            $scope.claim.newMessage.senderId = $scope.claim.brokerUserId;
            $scope.claim.newMessage.senderCompanyId = $scope.claim.brokerId;
            $scope.recipiants.push({
                name: $scope.supplierName,
                email: $scope.claim.supplierEmail
                
            });
            if (!$scope.claim.notifiedInvitedUser && $scope.claim.customerId) {
                $scope.recipiants.push({
                    name: $scope.customerName,
                    email: $scope.claim.customerEmail
                });
            }
        }
        else if ($scope.isSupplier) {
            $scope.claim.newMessage.senderEmail = $scope.claim.supplierEmail;
            $scope.claim.newMessage.senderId = $scope.claim.supplierUserId;
            $scope.claim.newMessage.senderCompanyId = $scope.claim.supplierId;
            if (!$scope.claim.notifiedInvitedUser && $scope.claim.customerId) {
                $scope.recipiants.push({
                    name: $scope.customerName,
                    email: $scope.claim.customerEmail
                });
            }
            $scope.recipiants.push({
                name: $scope.brokerName,
                email: $scope.claim.brokerEmail
            });
        }
        else if ($scope.isCustomer) {
            $scope.claim.newMessage.senderEmail = $scope.claim.customerEmail;
            $scope.claim.newMessage.senderId = $scope.claim.customerUserId;
            $scope.claim.newMessage.senderCompanyId = $scope.claim.customerId;
            $scope.recipiants.push({
                name: $scope.supplierName,
                email: $scope.claim.supplierEmail
            });
            $scope.recipiants.push({
                name: $scope.brokerName,
                email: $scope.claim.brokerEmail
            });
        };
        if (!$scope.claim.newMessage.recipiantEmail) {
            $scope.claim.newMessage.recipiantEmail = $scope.recipiants[0].email;
        }

    }
]);