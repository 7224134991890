app.controller('publicNotesController', [
    '$scope', 'claimEditService', '$timeout',
    function ($scope, claimEditService, $timeout) {

            claimEditService.setupScope($scope);


        var _setFocus = function (item) {
            var timer = $timeout(function () {
                $timeout.cancel(timer);
                item.focus = true;
            }, 20);
        };

 




   }
]);