app.controller('claimDamageController', [
    '$scope', 'claimEditService', '$timeout',
    function($scope, claimEditService, $timeout) {

        claimEditService.runCheckList();
        claimEditService.setupScope($scope);

        var _setFocus = function(item) {
            var timer = $timeout(function() {
                $timeout.cancel(timer);
                item.focus = true;
            }, 20);
        };

        var _newEntry = function() {
            $scope.damageButton = 'Add Damage Item';
            return {
                damageDetail: undefined,
                damageEntry: '',
                edit: true,
                focus: false,
            };
        };
        var _editItem;

        var _getDamageItems = function() {
            var damageItems = [];
            angular.forEach($scope.claim.details, function(detail) {
                var item = {
                    damageDetail: detail,
                    damageEntry: detail.description,
                    edit: false,
                    focus: false,
                }
                damageItems.push(item);
            });
            if (!$scope.isCustomer) {
                _editItem = _newEntry();
                damageItems.push(_editItem);
            }
            return damageItems;
        };

        $scope.damageItems = _getDamageItems();


        $scope.editDamage = function() {
            var item = this.damage;
            $scope.damageItems.splice($scope.damageItems.length - 1, 1);
            item.edit = true;
            item.focus = false;
            $scope.damageButton = 'Update Damage Item';
            _setFocus(item);
        }

        $scope.addDamage = function() {
            var item = this.damage;
            doAddDamage(item);
        }

        function doAddDamage(item) {
            if (item.damageDetail !== undefined) {
                item.damageDetail.description = item.damageEntry;
                item.edit = false;
                item.focus = false;
            } else {
                $scope.damageItems.splice($scope.damageItems.length - 1, 1);
                var newDetail = { description: item.damageEntry };
                $scope.claim.details.push(newDetail);
                var i = {
                    damageDetail: newDetail,
                    damageEntry: newDetail.description,
                    edit: false,
                };
                $scope.damageItems.push(i);
            }
            var newItem = _newEntry();
            $scope.damageItems.push(newItem);
            claimEditService.setClaimDirty(true);
            claimEditService.runCheckList();
            _setFocus(newItem);
        }


        $scope.removeDamage = function() {
            var item = this.damage;
            var index = $scope.damageItems.indexOf(item);
            $scope.claim.details.splice(index, 1);
            $scope.damageItems.splice(index, 1);
            claimEditService.setClaimDirty(true);
        };

//        _setFocus(_editItem);
    }
]);