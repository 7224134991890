app.controller('documentsController', [
    '$scope',
    'claimEditService',
    'documentUploadService',
    'ngDialog',
    '$q',
    function ($scope, claimEditService, documentUploadService, ngDialog, $q) {

        claimEditService.runCheckList();
        claimEditService.setupScope($scope);

        var _confirmDeleteDocument = function () {
            $scope.dialogHeading = 'Confirm Delete Document';
            $scope.dialogBody = 'Do you want to delete this Document?\n Click on the document name if you want to view it.';
            $scope.dialogConfirm = 'Delete';
            $scope.dialogCancel = 'Cancel';
            var deferred = $q.defer();
            ngDialog.openConfirm({
                template: 'app/views/dialogs/confirmDialog.html',
                scope: $scope,
            }).then(function (yes) {
                deferred.resolve(yes);
            }, function (no) {
                deferred.reject(no);
            });
            return deferred.promise;
        };


        $scope.statuses = ['Doc. type required', 'Ready to upload', 'Uploaded'];

        $scope.statusIndicator = function(stat) {
            if (stat === 0) return { danger: true };
            if (stat === 1) return { warning: true };
            return { success: true };
        };

        // Document upload support

        $scope.onFileSelect = function ($files) {
            console.log('scope flags', $scope.flags);
            angular.forEach($files, function(file) {
                var doc = {
                    id: 0,
                    url: '',
                    file: file,
                    name: resolveFileName(file.name),
                    size: file.size,
                    date: new Date(),
                    type: file.type,
                    purpose: ($scope.isBroker && $scope.flags.isSupInvoiced) ? $scope.docTypes[12] : $scope.documentTypes[0],
                    status: ($scope.isBroker && $scope.flags.isSupInvoiced) ? 1 : 0,
                    typeChanged: false,
                }
                $scope.documentFiles.push(doc);
            });
            $scope.docFilesUpdate();
        };
        
        var resolveFileName = function (fileName) {
            var origName = fileName;
            var num = 0;
            while (fileWithName(fileName)) {
                fileName = incrFileVersion(origName, num);
                num++;
            }
            return fileName;
        }

        var incrFileVersion = function(name, num) {
            var parts = name.split('.');
            parts[0] = parts[0] + '(' + num + ')';
            return parts.join('.');
        }

        var fileWithName = function(fileName) {
            for (var i = 0; i < $scope.documentFiles.length; i++) {
                if ($scope.documentFiles[i].name === fileName) {
                    return $scope.documentFiles[i].name;
                }
            }
            return undefined;
        }

        $scope.uploadDocuments = function() {
            claimEditService.uploadDocuments();
            $scope.docFilesUpdate();
        };

        var _removeDocument = function(document) {
            documentUploadService.deleteDocument(document).then(function(data) {
                var index = $scope.documentFiles.indexOf(document);
                $scope.documentFiles.splice(index, 1);
                $scope.docFilesUpdate();
                claimEditService.runCheckList();
            }, function(error) {
                console.log(error);
            });
        };

        $scope.dragOverClass = function($event) {
            var items = $event.dataTransfer.items;
            var hasFile = false;
            if (items != null) {
                for (var i = 0; i < items.length; i++) {
                    if (items[i].kind == 'file') {
                        hasFile = true;
                        break;
                    }
                }
            } else {
                hasFile = true;
            }
            $scope.docFilesUpdate();
            return hasFile ? "dragover" : "dragover-err";
        };

        $scope.docFilesUpdate = function () {
            if (this.doc) {
                var item = this.doc;
                if ($scope.isBroker && item.status === 2) {
                    item.typeChanged = true;
                }
            }
            var docTypesAssigned = true;
            var filesToBeLoaded = false;
            for (var i = 0; i < $scope.documentFiles.length; i++) {
                if ($scope.documentFiles[i].status < 2) {
                    if ($scope.documentFiles[i].purpose.value === -1) {
                        $scope.documentFiles[i].status = 0;
                        docTypesAssigned = false;
                    } else {
                        $scope.documentFiles[i].status = 1;
                        filesToBeLoaded = true;
                    }
                }
            }



            claimEditService.setDocTypesAssigned(docTypesAssigned);
            claimEditService.setFilesToBeLoaded(filesToBeLoaded);
            claimEditService.runCheckList();
        };

        $scope.deleteDocument = function() {
            var item = this.doc;
            _confirmDeleteDocument().then(function() { return _removeDocument(item); }, function() {});
        };

        $scope.docFilesUpdate();
    }
]);