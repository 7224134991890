app.factory('claimsService', ['$http', '$q', 'authService', function ($http, $q, authService) {

    var claimsServiceFactory = {};

    var _getClaims = function() {
        var url = "api/claims" + '?' + _getQueryString();
        var deferred = $q.defer();

        $http.get(url).success(function (data, status, headers, config) {
            deferred.resolve(data);
        }).error(function (error, status, headers, config) {
            deferred.reject(error);
        });
        return deferred.promise;
    }

    var _getQueryString = function () {
        var coType = authService.authentication.companyType;
        var coId = authService.authentication.companyId;
        var query = '';
        if (coType === "supplier") query += "supplierId=" + coId;
        if (coType === "customer") query += "customerId=" + coId;
        if (coType === "broker") query += "brokerId=" + coId;
        console.log('query string', query);
        return query;
    }

    claimsServiceFactory.getClaims = _getClaims;
    claimsServiceFactory.getQueryString = _getQueryString;
    return claimsServiceFactory;
}])