app.controller('supplierEditController', [
    '$scope',
    '$state',
    'supplierService',
    'supplier',
    'ngDialog',
    'authService',
    '$location',
    '$q',
    function($scope, $state, supplierService, supplier, ngDialog, authService, $location, $q) {

        $scope.supplier = supplier;

        var message = 'Assured Vehicle Rental have introduced a bespoke Incident Management System (IMS)\n';
        message += 'to process all damage claims and supporting documentation and invoices.\n';
        message += 'We will no longer be responding or collecting emails from the address damage@avrhire.com\n';
        message += 'and any emails and attached documents will be rejected.\n';
        message += 'You are invited to register with IMS in order to manage your damage claims\n';
        message += 'Please click on the link below or copy it into your browser address bar\n';
        message += 'If you have any queries call 01323 847 444\n';

        if ($scope.supplier.id === 0) {
            $scope.btnName = 'Add Supplier';
        } else {
            $scope.btnName = 'Update Supplier';
        };

        $scope.errorMessage = '';

        $scope.impersonateUser = function () {
            var item = this.user;
            authService.impersonateUser(item).then(function (data) {
                $state.go('claims');
            }, function (error) { });
        };

        $scope.save = function () {
            supplierService.updateSupplier($scope.supplier).then(function(data) {
                $scope.supplier = data;
                $state.go('suppliers');
            }, function(error) {
                console.log(error);
                $scope.errorMessage = error;
            });
        }
        $scope.invite = {
            emailTo: '',
            message: message,
        };

        $scope.inviteUser = function() {
            ngDialog.open({
                template: 'app/views/dialogs/inviteUserToRegister.html',
                scope: $scope,
            });
        };

        $scope.closeInvite = function() {
            ngDialog.close();
            $scope.invite.emailTo = '';
        };

        var _getSignupUrl = function(code) {
            var url = $location.absUrl();
            var signupUrl = url.slice(0, url.indexOf('#')) + '#/signup';

            return signupUrl + '/';
        }

        $scope.sendInvite = function() {
            var message = {
                mailTo: $scope.invite.emailTo,
                mailFrom: authService.authentication.userEmail,
                mailSubject: 'Please register with AVR IMS',
                mailBody: $scope.invite.message,
                mailLink: _getSignupUrl(),
            };
            var invite = {
                email: $scope.invite.emailTo,
                messageModel: message,
                companyId: $scope.supplier.id,
            }
            supplierService.inviteUser(invite).then(function (data) {
                $scope.supplier.userInvitations.push(data);
                $scope.invite.message = '';
                alert('Invitation sent');
                $scope.closeInvite();
            }, function(error) {
                alert('invitation failed:', error);
            });
        };

        var _confirmDeleteInvitation = function () {
            $scope.dialogHeading = 'Confirm Delete Invitation';
            $scope.dialogBody = 'Do you want to delete this invitation to register?';
            $scope.dialogConfirm = 'Delete';
            $scope.dialogCancel = 'Cancel';
            var deferred = $q.defer();
            ngDialog.openConfirm({
                template: 'app/views/dialogs/confirmDialog.html',
                scope: $scope,
            }).then(function (yes) {
                deferred.resolve(yes);
            }, function (no) {
                deferred.reject(no);
            });
            return deferred.promise;
        };

        var _deleteInvitation = function (item) {
            var deferred = $q.defer();
            supplierService.deleteInvitation(item).then(function (data) {
                $scope.supplier.userInvitations.splice($scope.supplier.userInvitations.indexOf(item), 1);
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
                console.log(error);
            });
        };

        $scope.deleteInvitation = function () {
            var item = this.invite;
            _confirmDeleteInvitation().then(function () { return _deleteInvitation(item); }, function () { });
        };

        var _confirmDeleteUser = function () {
            $scope.dialogHeading = 'Confirm Delete User';
            $scope.dialogBody = 'Do you want to delete this user?';
            $scope.dialogConfirm = 'Delete';
            $scope.dialogCancel = 'Cancel';
            var deferred = $q.defer();
            ngDialog.openConfirm({
                template: 'app/views/dialogs/confirmDialog.html',
                scope: $scope,
            }).then(function (yes) {
                deferred.resolve(yes);
            }, function (no) {
                deferred.reject('no');
            });
            return deferred.promise;
        };

        var _deleteUser = function (item) {
            var deferred = $q.defer();
            supplierService.deleteUser(item).then(function (data) {
                $scope.supplier.users.splice($scope.supplier.users.indexOf(item), 1);
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
                console.log(error);
            });
            return deferred.promise;
        };

        $scope.deleteUser = function () {
            var item = this.user;
            _confirmDeleteUser()
                .then(function () { return _deleteUser(item); })
                .then(function () { }, function (error) {
                if (error !== 'no') {
                    return _notifyUser(error);
                } else {
                    return null;
                }
            });
        };

        var _notifyUser = function (message) {
            var deferred = $q.defer();
            $scope.dialogHeading = 'Cannot delete user';
            $scope.dialogBody = 'This user is involved with a number of open claims. These claims must be archived before the user can be deleted';
            $scope.dialogClose = 'OK';
            ngDialog.openConfirm({
                template: 'app/views/dialogs/warningDialog.html',
                scope: $scope,
            }).then(function () { deferred.resolve(true); }, function () { deferred.reject(false); });
            return deferred.promise;
        };
    }
])