app.factory('documentUploadService', [
    '$upload',
    '$q',
    '$http',
    function ($upload, $q, $http) {

    var documentUploadServiceFactory = {};

    var _deleteDocument = function (document) {
        var deferred = $q.defer();
        if (document.id > 0) {
            $http.delete("api/claims/documents/" + document.id).success(function(data) {
                deferred.resolve(data);
            }).error(function(error) {
                deferred.reject(error);
            });
        } else {
            deferred.resolve();
        }
        return deferred.promise;
    };

    var _progress;
    var _errorMsg;
    var _upload;
    var _uploadUrl = "api/claims/documents/files";


    var _uploadDocument = function (docFile, docModel) {
        _progress = 0;
        _errorMsg = null;
        var deferred = $q.defer();

        var fileReader = new FileReader();
        fileReader.onload =
            function () {
                _upload = $upload.upload({
                    url: _uploadUrl,
                    method: 'POST',
                    headers: { 'Content-Type': undefined },
                    file: docFile,
                    data: docModel,
                }).success(function (data) {
                    console.log(data);
                    deferred.resolve(data);
                }).error(function (error) {
                    console.log(error);
                    deferred.reject(error);
                });
            }
        fileReader.readAsArrayBuffer(docFile);
        return deferred.promise;
    };


    documentUploadServiceFactory.deleteDocument = _deleteDocument;
    documentUploadServiceFactory.uploadDocument = _uploadDocument;

    return documentUploadServiceFactory;

}])