app.controller('customerListController', [
    '$scope',
    'customerListService',
    '$state',
    '$q',
    'ngDialog',
    function($scope, customerListService, $state, $q, ngDialog) {

        $scope.refreshList = function() {
            customerListService.getCustomerList().then(function(data) {
                $scope.customers = data;
            }, function(error) {
                console.log(error);
            });
        };

        $scope.refreshList();

        $scope.editCustomer = function(item) {
            $state.go('customerEdit', { id: item.id });
        };

        var _confirmDeleteCustomer = function () {
            $scope.dialogHeading = 'Confirm Delete Customer';
            $scope.dialogBody = 'Do you want to delete this customer?';
            $scope.dialogConfirm = 'Delete';
            $scope.dialogCancel = 'Cancel';
            var deferred = $q.defer();
            ngDialog.openConfirm({
                template: 'app/views/dialogs/confirmDialog.html',
                scope: $scope,
            }).then(function (yes) {
                deferred.resolve(yes);
            }, function (no) {
                deferred.reject('no');
            });
            return deferred.promise;
        };

        var _deleteCustomer = function (item) {
            var deferred = $q.defer();
            customerListService.deleteCustomer(item).then(function (data) {
                $scope.customers.splice($scope.customers.indexOf(item), 1);
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
                console.log(error);
            });
            return deferred.promise;
        };

        $scope.deleteCustomer = function (item) {
            console.log('customer item', item);
            _confirmDeleteCustomer()
                .then(function () { return _deleteCustomer(item); })
                .then(function() {}, function(error) {
                    if (error !== 'no') {
                        return _notifyUser(error);
                    } else {
                        return null;
                    }
            });
        };

        var _notifyUser = function (message) {
            var deferred = $q.defer();
            console.log('notify called');
            $scope.dialogHeading = 'Cannot delete customer';
            $scope.dialogBody = 'This customer is involved with a number of open claims. These claims must be archived before the user can be deleted';
            $scope.dialogClose = 'OK';
            ngDialog.openConfirm({
                template: 'app/views/dialogs/warningDialog.html',
                scope: $scope,
            }).then(function () { deferred.resolve(true); }, function () { deferred.reject(false); });
            return deferred.promise;
        };
    }
]);