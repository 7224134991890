app.factory('customerService', [
    '$http', '$q', 'brokerSupportService',
    function($http, $q, brokerSupportService) {

        var customerServiceFactory = {};

        var _newCustomer = {
            id: 0,
            name: '',
            telephone: '',
            registrationId: '',
            users: [],
        };
        _newCustomer.clear = function() {
            this.id = 0;
            this.name = '';
            this.telephone = '';
            this.registrationId = '';
            this.users = [];
            return this;
        };

        var _getCustomer = function(id) {
            var deferred = $q.defer();
            if (id === '0') {
                deferred.resolve(_newCustomer.clear());
                return deferred.promise;
            }
            var url = 'api/customers/' + id;
            $http.get(url).success(function(data) {
                deferred.resolve(data);
            }).error(function(error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _updateCustomer = function(customer) {
            var url = 'api/customers';
            var deferred = $q.defer();
            if (customer.id === 0) {
                $http.post(url, customer).success(function(data) {
                    brokerSupportService.resetCustomerList();
                    deferred.resolve(data);
                }).error(function(error) {
                    deferred.reject(error);
                });
            } else {
                url = 'api/customers/' + customer.id;
                $http.put(url, customer).success(function(data) {
                    brokerSupportService.resetCustomerList();
                    deferred.resolve(data);
                }).error(function(error) {
                    deferred.reject(error);
                });
            }
            return deferred.promise;
        };

        var _inviteUser = function(invite) {
            var deferred = $q.defer();
            var url = 'api/users/invite';
            $http.post(url, invite).success(function(data) {
                deferred.resolve(data);
            }).error(function(error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _deleteInvitation = function(invitation) {
            var url = 'api/users/invitations/' + invitation.id;
            var deferred = $q.defer();
            $http.delete(url).success(function(data) {
                deferred.resolve(data);
            }).error(function(error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _deleteUser = function(user) {
            var url = 'api/users/' + user.id;
            var deferred = $q.defer();
            $http.delete(url).success(function(data) {
                deferred.resolve(data);
            }).error(function (error) {
                console.log('delete user error', error);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        customerServiceFactory.getCustomer = _getCustomer;
        customerServiceFactory.updateCustomer = _updateCustomer;
        customerServiceFactory.inviteUser = _inviteUser;
        customerServiceFactory.deleteInvitation = _deleteInvitation;
        customerServiceFactory.deleteUser = _deleteUser;

        return customerServiceFactory;
    }
]);