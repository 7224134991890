'use strict';
app.factory('authService', [
    '$http', '$q', 'brokerSupportService', 'localStorageService',
    function($http, $q, brokerSupportService, localStorageService) {

        var authServiceFactory = {};

        var _authentication = {};

        var _registerUser = function(user) {
            var deferred = $q.defer();
            var url = 'api/users/register';
            $http.post(url, user).success(function(data) {
                deferred.resolve(data);
            }).error(function(error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _newAuthentication = function () {
            console.log('new authentication');
            return {
                isAuth: false,
                userName: '',
                userId: '',
                companyId: null,
                companyType: '',
                companyName: '',
                isAuthSupplier: false,
                isAuthBroker: false,
                isAuthCustomer: false,
                broker: {},
            }
        };

        var _login = function(loginData) {
            var deferred = $q.defer();
            _doLogin(loginData)
                .then(function() { return _getBroker(); })
                .then(function() { deferred.resolve(); }, function(error) {
                    console.log('login failed');
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _impersonateUser = function(user) {
            var deferred = $q.defer();
            _doImpersonateUser(user)
                .then(function() { return _getBroker(); })
                .then(function() { deferred.resolve(); }, function(error) {
                    console.log('Impersonate user failed');
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _doImpersonateUser = function (user) {
            localStorageService.add('brokerAuth', _authentication);
            var deferred = $q.defer();
            var url = 'api/users/impersonate/' + user.id;
            $http.get(url).success(function (response) {
                var auth = _mapResponse(response);
                angular.extend(_authentication, auth);
                _authentication.impersonation = true;
                deferred.resolve(_authentication);
            }).error(function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };


        var _getBroker = function() {
            var deferred = $q.defer();
            brokerSupportService.getBroker().then(function(data) {
                _authentication.broker = data;
                deferred.resolve();
            }, function(error) {
                console.log('get Broker failed', error);
                deferred.reject();
            });
            return deferred.promise;
        }

        var _mapResponse = function(response) {
            var auth = {
                isAuth: true,
                impersonation: false,
                userName: response.name,
                userId: response.id,
                companyId: response.companyId,
                companyType: response.companyType,
                companyName: response.companyName,
                isAuthSupplier: response.companyType === 'supplier',
                isAuthCustomer: response.companyType === 'customer',
                isAuthBroker: response.companyType === 'broker',
                userEmail: response.email,
            };
            return auth;
        };

        var _doLogin = function(loginData) {

            var url = "api/users/login";
            var deferred = $q.defer();

            $http.post(url, loginData)
                .success(function(response, status, headers, config) {
                    var auth = _mapResponse(response);
                    angular.extend(_authentication, auth);
                    if (loginData.stayLoggedIn) {
                        localStorageService.set('authData', auth);
                    }
                    deferred.resolve(response);
                }).error(function(error, status, headers, config) {
                    _logOut();
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        var _logOut = function() {
            console.log('log out');
            localStorageService.remove('authData');
            angular.extend(_authentication, _newAuthentication());
        };

        var _fillAuthData = function () {

            var authData = localStorageService.get('authData');
            console.log('fill auth', authData);
            if (authData) {
                 angular.extend(_authentication, authData);
            } else {
                 angular.extend(_authentication, _newAuthentication());
            }
        }

        var _getUserDetails = function() {
            var deferred = $q.defer();
            var url = 'api/users/' + _authentication.userId;
            $http.get(url).success(function(data) {
                deferred.resolve(data);
            }).error(function (error) {
                console.log('get user', error);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _deferredState = {
            state: undefined,
            params: undefined,
        }

        var _setDeferredState = function(state, params) {
            _deferredState.state = state;
            _deferredState.params = params;
        };

        var _getDeferredState = function() {
            var theState = {
                state: _deferredState.state,
                params: _deferredState.params,
            }
            return theState;
        };

        var _resetDeferredState = function() {
            _deferredState.state = undefined;
            _deferredState.params = undefined;
        };

        var _updateUserDetails = function(details) {
            var deferred = $q.defer();
            var url = 'api/users/' + details.id;
            $http.put(url, details).success(function(data) {
                _authentication.userName = data.name;
                _authentication.userEmail = data.email;
                deferred.resolve(data);
            }).error(function(error) {
                console.log('put user', error);
                deferred.reject(error);
            });
            return deferred.promise;
        }

        var _resetPassword = function (password) {
            var deferred = $q.defer();
            var url = 'api/users/password/' + _authentication.userId;
            $http.put(url, {password: password }).success(function(data) {
                deferred.resolve(data);
            }).error(function(error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        var _resetImpersonateUser = function () {
            var deferred = $q.defer();
            var auth = localStorageService.get('brokerAuth');
            if (auth) {
                angular.extend(_authentication, auth);
                deferred.resolve(_authentication);
            } else {
                deferred.reject('Broker not found');
            }
            return deferred.promise;
        }

        var _identification = function() {
            var id = 'user: ' + _authentication.userName;
            if (_authentication.impersonation) {
                id = id + '(I)';
            }
            return id;
        }


        authServiceFactory.login = _login;
        authServiceFactory.logOut = _logOut;
        authServiceFactory.registerUser = _registerUser;
        authServiceFactory.fillAuthData = _fillAuthData;
        authServiceFactory.authentication = _authentication;
        authServiceFactory.getUserDetails = _getUserDetails;
        authServiceFactory.updateUserDetails = _updateUserDetails;
        authServiceFactory.setDeferredState = _setDeferredState;
        authServiceFactory.getDeferredState = _getDeferredState;
        authServiceFactory.resetDeferredState = _resetDeferredState;
        authServiceFactory.resetPassword = _resetPassword;
        authServiceFactory.impersonateUser = _impersonateUser;
        authServiceFactory.resetImpersonateUser = _resetImpersonateUser;
        authServiceFactory.identification = _identification;

        return authServiceFactory;
    }
]);

//    var serviceBase = ngAuthSettings.apiServiceBaseUri;
//    var authServiceFactory = {};
//
//    var _authentication = {
//        isAuth: false,
//        userName: "",
//        useRefreshTokens: false
//    };
//
//    var _externalAuthData = {
//        provider: "",
//        userName: "",
//        externalAccessToken: ""
//    };
//
//    var _saveRegistration = function (registration) {
//
//        _logOut();
//
//        return $http.post(serviceBase + 'api/account/register', registration).then(function (response) {
//            return response;
//        });
//
//    };
//
//    var _login = function (loginData) {
//
//        var data = "grant_type=password&username=" + loginData.userName + "&password=" + loginData.password;
//
//        if (loginData.useRefreshTokens) {
//            data = data + "&client_id=" + ngAuthSettings.clientId;
//        }
//
//        var deferred = $q.defer();
//
//        $http.post(serviceBase + 'token', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).success(function (response) {
//
//            if (loginData.useRefreshTokens) {
//                localStorageService.set('authorizationData', { token: response.access_token, userName: loginData.userName, refreshToken: response.refresh_token, useRefreshTokens: true });
//            }
//            else {
//                localStorageService.set('authorizationData', { token: response.access_token, userName: loginData.userName, refreshToken: "", useRefreshTokens: false });
//            }
//            _authentication.isAuth = true;
//            _authentication.userName = loginData.userName;
//            _authentication.useRefreshTokens = loginData.useRefreshTokens;
//
//            deferred.resolve(response);
//
//        }).error(function (err, status) {
//            _logOut();
//            deferred.reject(err);
//        });
//
//        return deferred.promise;
//
//    };
//
//    var _logOut = function () {
//
//        localStorageService.remove('authorizationData');
//
//        _authentication.isAuth = false;
//        _authentication.userName = "";
//        _authentication.useRefreshTokens = false;
//
//    };
//
//    var _fillAuthData = function () {
//
//        var authData = localStorageService.get('authorizationData');
//        if (authData) {
//            _authentication.isAuth = true;
//            _authentication.userName = authData.userName;
//            _authentication.useRefreshTokens = authData.useRefreshTokens;
//        }
//
//    };
//
//    var _refreshToken = function () {
//        var deferred = $q.defer();
//
//        var authData = localStorageService.get('authorizationData');
//
//        if (authData) {
//
//            if (authData.useRefreshTokens) {
//
//                var data = "grant_type=refresh_token&refresh_token=" + authData.refreshToken + "&client_id=" + ngAuthSettings.clientId;
//
//                localStorageService.remove('authorizationData');
//
//                $http.post(serviceBase + 'token', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).success(function (response) {
//
//                    localStorageService.set('authorizationData', { token: response.access_token, userName: response.userName, refreshToken: response.refresh_token, useRefreshTokens: true });
//
//                    deferred.resolve(response);
//
//                }).error(function (err, status) {
//                    _logOut();
//                    deferred.reject(err);
//                });
//            }
//        }
//
//        return deferred.promise;
//    };
//
//    var _obtainAccessToken = function (externalData) {
//
//        var deferred = $q.defer();
//
//        $http.get(serviceBase + 'api/account/ObtainLocalAccessToken', { params: { provider: externalData.provider, externalAccessToken: externalData.externalAccessToken } }).success(function (response) {
//
//            localStorageService.set('authorizationData', { token: response.access_token, userName: response.userName, refreshToken: "", useRefreshTokens: false });
//
//            _authentication.isAuth = true;
//            _authentication.userName = response.userName;
//            _authentication.useRefreshTokens = false;
//
//            deferred.resolve(response);
//
//        }).error(function (err, status) {
//            _logOut();
//            deferred.reject(err);
//        });
//
//        return deferred.promise;
//
//    };
//
//    var _registerExternal = function (registerExternalData) {
//
//        var deferred = $q.defer();
//
//        $http.post(serviceBase + 'api/account/registerexternal', registerExternalData).success(function (response) {
//
//            localStorageService.set('authorizationData', { token: response.access_token, userName: response.userName, refreshToken: "", useRefreshTokens: false });
//
//            _authentication.isAuth = true;
//            _authentication.userName = response.userName;
//            _authentication.useRefreshTokens = false;
//
//            deferred.resolve(response);
//
//        }).error(function (err, status) {
//            _logOut();
//            deferred.reject(err);
//        });
//
//        return deferred.promise;
//
//    };
//
//    authServiceFactory.saveRegistration = _saveRegistration;
//    authServiceFactory.login = _login;
//    authServiceFactory.logOut = _logOut;
//    authServiceFactory.fillAuthData = _fillAuthData;
//    authServiceFactory.authentication = _authentication;
//    authServiceFactory.refreshToken = _refreshToken;
//
//    authServiceFactory.obtainAccessToken = _obtainAccessToken;
//    authServiceFactory.externalAuthData = _externalAuthData;
//    authServiceFactory.registerExternal = _registerExternal;
//
//    return authServiceFactory;
//}]);