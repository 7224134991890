'use strict';
app.controller('signupController', [
    '$scope', '$state', '$timeout', 'authService', '$stateParams',
    function ($scope, $state, $timeout, authService, $stateParams) {

        $scope.savedSuccessfully = false;
        $scope.message = "";

        var regId = $stateParams.regId;

        $scope.signupValid = false;

        $scope.$watchCollection('$scope.registration', function () {
            console.log()
            if ($scope.registration.password.length >= 4 &&
                $scope.registration.password === $scope.registration.confirmPassword &&
                $scope.loginForm.$valid) {
                $scope.signupValid = true;
            } else {
                $scope.signupValid = false;
            }
        });


        $scope.checkPasswordLength = function (password) {
            console.log('password:', password);
            return  password.length >= 4;
        }

        $scope.registration = {
            userName: "",
            password: "",
            confirmPassword: "",
            email: "",
            registrationId: regId,
            contactTelephon: "",
        };

        $scope.signUp = function() {
            $scope.savedSuccessfully = false;
            $scope.message = "";
            authService.registerUser($scope.registration).then(function(data) {
                $scope.savedSuccessfully = true;
                $scope.message = "User has been registered successfully, you will be redicted to login page in 2 seconds.";
                startTimer();
            }, function(error) {
                $scope.savedSuccessfully = false;
                $scope.message = error.message;
            });
        };
        var startTimer = function() {
            var timer = $timeout(function() {
                $timeout.cancel(timer);
                $state.go('login');
            }, 2000);
        };


    }
]);




//    $scope.savedSuccessfully = false;
//    $scope.message = "";
//
//    $scope.registration = {
//        userName: "",
//        password: "",
//        confirmPassword: ""
//    };
//
////    $scope.signUp = function () {
////
////        authService.saveRegistration($scope.registration).then(function (response) {
////
////            $scope.savedSuccessfully = true;
////            $scope.message = "User has been registered successfully, you will be redicted to login page in 2 seconds.";
////            startTimer();
////
////        },
////         function (response) {
////             var errors = [];
////             for (var key in response.data.modelState) {
////                 for (var i = 0; i < response.data.modelState[key].length; i++) {
////                     errors.push(response.data.modelState[key][i]);
////                 }
////             }
////             $scope.message = "Failed to register user due to:" + errors.join(' ');
////         });
////    };
//
//    var startTimer = function () {
//        var timer = $timeout(function () {
//            $timeout.cancel(timer);
//            $location.path('/login');
//        }, 2000);
//    }
//
//}]);