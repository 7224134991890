app.controller('supplierListController', [
    '$scope',
    'supplierListService',
    '$state',
    '$q',
    'ngDialog',
    function ($scope, supplierListService, $state, $q, ngDialog) {

    $scope.refreshList = function () {
        supplierListService.getSupplierList().then(function (data) {
            $scope.suppliers = data;
        }, function (error) {
            console.log(error);
        });
    };

    $scope.refreshList();

    $scope.editSupplier = function (item) {
        $state.go('supplierEdit', { id: item.id });
    };

    var _deleteSupplier = function (item) {
        var deferred = $q.defer();
        supplierListService.deleteSupplier(item).then(function (data) {
            $scope.suppliers.splice($scope.suppliers.indexOf(item), 1);
            deferred.resolve(data);
        }, function (error) {
            deferred.reject(error);
            console.log('delete sup', error);
        });
        return deferred.promise;
    };
    var _confirmDeleteSupplier = function () {
        $scope.dialogHeading = 'Confirm Delete Supplier';
        $scope.dialogBody = 'Do you want to delete this supplier?';
        $scope.dialogConfirm = 'Delete';
        $scope.dialogCancel = 'Cancel';
        var deferred = $q.defer();
        ngDialog.openConfirm({
            template: 'app/views/dialogs/confirmDialog.html',
            scope: $scope,
        }).then(function (yes) {
            deferred.resolve(yes);
        }, function (no) {
            deferred.reject('no');
        });
        return deferred.promise;
    };

    $scope.deleteSupplier = function (item) {
        _confirmDeleteSupplier()
            .then(function () { return _deleteSupplier(item); })
            .then(function () { }, function(error) {
            if (error !== 'no') {
                return _notifyUser(error);
            } else {
                return null;
            }
        });
    };

    var _notifyUser = function (message) {
        var deferred = $q.defer();
        console.log('notify called');
        $scope.dialogHeading = 'Cannot delete user';
        $scope.dialogBody = 'This supplier is involved with a number of open claims. These claims must be archived before the user can be deleted';
        $scope.dialogClose = 'OK';
        ngDialog.openConfirm({
            template: 'app/views/dialogs/warningDialog.html',
            scope: $scope,
        }).then(function () { deferred.resolve(true); }, function () { deferred.reject(false); });
        return deferred.promise;
    };
}])