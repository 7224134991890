app.factory('claimService',
    ['$http',
    '$q',
    function ($http, $q) {

    var claimServiceFactory = {};

    var _newClaim = {
        claimDate: new Date(),
        incidentDate: undefined,
        supplierRef: '',
        customerRef: '',
        brokerRef: '',
        damageClaimId: 0,
        registration: '',
        makeAndModel: '',
        driverName: '',
        supplierId: '',
        brokerId: '',
        customerId: '',
        supplierUserId: '',
        brokerIdUser: '',
        customerIdUser: '',
        supplierName: '',
        brokerName: '',
        privateNotes: '',
        brokerNotes: '',
        customerName: '',
        status: 'Draft',
        lastAction: '',
        claimValue: '',
        customerOrderNumber: '',
        sendClaimEmail: false,
        details: [],
        documents: [],
        messages: []
    }

    var _getClaim = function(id) {
        var deferred = $q.defer();
        if (id === '0') {
            var claim = {};
            angular.copy(_newClaim, claim);
            deferred.resolve(claim);
        } else {
            var url = 'api/claims/' + id;
            $http.get(url).success(function (data) {
                deferred.resolve(data);
            }).error(function(error) {
                deferred.reject(error);
            });
        }
        return deferred.promise;
    };

    var _ensureClaimHeader = function(header) {
        var url = 'api/claims/header';
        var deferred = $q.defer();
        if (header.damageClaimId === 0) {
            $http.post(url, header).success(function (data) {
                console.log('header posted', data);
                deferred.resolve(data);
            }).error(function (error) {
                console.log('header error', error);
                deferred.reject(error);
            });
        } else {
            deferred.resolve(header);
        }

        return deferred.promise;
    }

    var _updateClaim = function(claim) {
        var url = 'api/claims' + (claim.damageClaimId > 0 ? '/' + claim.damageClaimId : '');
        var deferred = $q.defer();
        if (claim.damageClaimId == 0) {
            $http.post(url, claim).success(function (data) {
                deferred.resolve(data);
            }).error(function(error) {
                deferred.reject(error);
            });
        } else {
            $http.put(url, claim).success(function(data) {
                deferred.resolve(data);
            }).error(function(error) {
                deferred.reject(error);
            });
        }
        return deferred.promise;
    };

    var _deleteClaim = function(claim) {
        if (claim.damageClaimId > 0) {
            var url = 'api/claims/' + claim.damageClaimId;
            var deferred = $q.defer();
            $http.delete(url).success(function(data) {
                deferred.resolve(data);
            }).error(function(error) {
                deferred.reject(val);
            });
            return deferred.promise;
        }
    };

    var _archiveClaim = function(claim) {
        var url = "api/claims/archive/" + claim.damageClaimId;
        var deferred = $q.defer();
        $http.put(url, claim).success(function(data) {
            deferred.resolve(data);
        }).error(function(error) {
            deferred.reject(error);
        });
        return deferred.promise;
    }


    claimServiceFactory.getClaim = _getClaim;
    claimServiceFactory.updateClaim = _updateClaim;
    claimServiceFactory.ensureClaimHeader = _ensureClaimHeader;
    claimServiceFactory.removeClaim = _deleteClaim;
    claimServiceFactory.archiveClaim = _archiveClaim;

    return claimServiceFactory;
}])