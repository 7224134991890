app.factory('errorService', [
    '$state', function($state) {

        var errorServiceFactory = {};
        var _errorDetail = {};

        var _showError = function (error) {
            _errorDetail = angular.toJson(error, true);
            console.log('error details:', _errorDetail);
            $state.go('errorPage');
        }

        var _getErrorDetails = function() {
            return _errorDetail;
        }

        errorServiceFactory.errorDetail = _errorDetail; 
        errorServiceFactory.showError = _showError;
        errorServiceFactory.getErrorDetail = _getErrorDetails;

        return errorServiceFactory;
    }
]);