app.factory('claimEditService', [
    'claimService',
    'authService',
    'brokerSupportService',
    'documentUploadService',
    '$q',
    '$rootScope',
    function(claimService, authService, brokerSupportService, documentUploadService, $q, $rootScope) {

        // tab support

        //Active tab

        var tabName = '';

        $rootScope.$on('$stateChangeStart', function (e, toState) {
            if (toState.name.indexOf('claimEdit') > -1) tabName = toState.name;
        });

        var _claim = {};
        var _claimDirty = false;

        var _newMessage = function() {
            return {
                id: 0,
                senderCompanyId: '',
                senderId: '',
                recipiantCompanyId: '',
                recipiantId: '',
                senderEmail: '',
                recipiantEmail: '',
                notificationLink: '',
                messageHeading: '',
                messageText: '',
            };
        };

        var _claimIsDirty = function () {
            return _scopeFlags.filesToBeLoaded || _claimDirty;
        }

        var _unassignedFiles = function () {
            for (var i = 0; i < _documentFiles.length; i++) {
                if (_documentFiles[i].status === 0) {
                    return true;
                }
            }
            return false;
        }

        var _setClaimDirty = function (val) {
            if (_claimDirty)return;
            _claimDirty = val;
        }

        var _resetClaimDirty = function() {
            _claimDirty = false;
        }

        var _docTypes = [
            { name: 'supplier notification advice', value: 12 },
            { name: 'client notification advice', value: 13 },
            { name: 'client pre existing email/images advice', value: 14 },
            { name: 'client manual booking request email/form', value: 15 },
            { name: 'client claim reference advice', value: 16 },
            { name: '3rd party claim correspondance', value: 17 },
            { name: 'delivery/collection sheet', value: 0 },
            { name: 'rental agreement', value: 10 },
            { name: 'accident report', value: 11 },
            { name: 'police report', value: 18 },
            { name: 'images of damaged areas & reg plate', value: 1 },
            { name: 'repairer assessment', value: 9 },
            { name: 'repairer estimate', value: 7 },
            { name: 'repairer audatex/glassmatix type estimate', value: 2 },
            { name: 'engineers report', value: 3 },
            { name: 'repairer jobsheet', value: 8 },
            { name: 'repairer invoice', value: 4 },
            { name: 'vehicle registration document', value: 19 },
            { name: 'stolen/write off vehicle valuation', value: 20 },
            { name: 'supplier invoice', value: 5 },
            { name: 'supplier credit note', value: 21 },
            { name: 'broker invoice', value: 6 },
            { name: 'broker revised invoice', value: 22 }
        ];
        _docTypes.getByValue = function (value) {
            for (var i = 0; i < this.length; i++) {
                if (this[i].value === value) return this[i];
            }
            return undefined;
        };

        var _documentTypes = [];
        _documentTypes.initialise = function (includeBrokerInvoice) {
            var count = !includeBrokerInvoice ? 21 : 23;
            this.length = 0;
            this.push({ name: '-- Choose document type --', value: -1 });
            for (var i = 0; i < count; i++) {
                this.push(_docTypes[i]);
            }
        }

        var _claimStatus;
        var _isSettled;


        var _documentFiles = [];
        _documentFiles.isDocTypePresent = function(docType) {
            for (var i = 0; i < this.length; i++) {
                if (this[i].purpose.value === docType) {
                    return true;
                }
            }
            return false;
        }

        var _scopeFlags = {
            filesToBeLoaded: false,
            docTypesAssigned: false,
            SupInvoiceRequested: false,
            isSupInvoiced: false,
            IsBrokerInvoiced: false,
            isAbandoned: false,
            isDisputed: false,
            isAuthorised: false,
            remittanceRcved: false,
        };


        var _setClaim = function (claim) {
            if (authService.authentication.isAuthSupplier) {
                claim.supplierId = authService.authentication.companyId;
                claim.supplierName = authService.authentication.companyName;
                claim.supplierUserId = authService.authentication.userId;
                claim.supplierEmail = authService.authentication.userEmail;
            }
            else if (authService.authentication.isAuthCustomer) {
                claim.customerUserId = authService.authentication.userId;
                claim.customerEmail = authService.authentication.userEmail;
            }
            else if (authService.authentication.isAuthBroker) {
                claim.brokerUserId = authService.authentication.userId;
                claim.brokerEmail = authService.authentication.userEmail;
            }
            _claimStatus = _claimStates.indexOf(claim.status);
            _scopeFlags.SupInvoiceRequested = _claimStatus >= 5;
            _scopeFlags.isSupInvoiced = _claimStatus >= 6;
            _scopeFlags.isBrokerInvoiced = _claimStatus >= 7;
            _scopeFlags.isAbandoned = _claimStatus === 11;
            _scopeFlags.isDisputed = _claimStatus === 8;
            _scopeFlags.isAuthorised = _claimStatus === 9;
            _scopeFlags.remittanceRcved = _claimStatus === 10;
            _documentTypes.initialise(_scopeFlags.SupInvoiceRequested);
            console.log('claim status', _claimStatus);
            _documentFiles.length = 0;
            angular.forEach(claim.documents, function(document) {
                var doc = {
                    id: document.id,
                    file: '',
                    url: document.documentUrlRoot + '/documents/' + document.location, 
                    name: document.documentName,
                    size: document.documentSize,
                    purpose: _docTypes.getByValue(document.documentType),
                    date: document.upLoadDate,
                    status: 2,
                    typeChanged: false,
                };
                _documentFiles.push(doc);
            });
            _claim = claim;
            if (_claim.damageClaimId === 0) {
                _claim.brokerName = authService.authentication.broker.name;
                _claim.brokerId = authService.authentication.broker.id;
                _claim.brokerUserId = authService.authentication.broker.userNameId;
                _claim.brokerEmail = authService.authentication.broker.userEmail;
            }
            _claim.newMessage = _newMessage();
            _resetClaimDirty();
        };

        var _claimStates = [
            'Temporary',
            'Draft',
            'New claim',
            'Notified',
            'Examined',
            'Inv. pending',
            'Inv. from Sup.',
            'Cust. invoiced',
            'Disputed',
            'Pmnt. auth.',
            'Remittance rcved',
            'Abandoned',
        ];


        var _saveClaim = function (claim, status) {
            var deferred = $q.defer();
            claim.status = status;
            claimService.updateClaim(claim).then(function(data) {
                _claim = data;
                _resetClaimDirty();
                deferred.resolve(data);
            },function(error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };


        var _uploadFiles = function () {
            for(var i = 0; i < _documentFiles.length; i++){
                if (_documentFiles[i].status === 1) {
                    return true;
                }
            };
            return false;
        };

        var _uploadDocuments = function() {
            var deferred = $q.defer();
            if (_uploadFiles()) {
                ensureClaimHeader()
                    .then(function() { return uploadDocuments(); })
                    .then(function() { return deferred.resolve(true); }, function() { deferred.reject('error'); });
            } else {
                deferred.resolve();
            }
            return deferred.promise;
        };


            function ensureClaimHeader() {
            var deferred = $q.defer();
            if (_claim.damageClaimId === 0) {
                var header = createClaimHeader();
                header.status = 'temporary';
                claimService.ensureClaimHeader(header).then(function (data) {
                    updateClaimFromHeader(data);
                    deferred.resolve(data);
                }, function(error) {
                    console.log(error);
                    deferred.reject(error);
                });
            } else {
                deferred.resolve(true);
            }
            return deferred.promise;
        };

        function uploadDocuments() {
            return $q.all(_documentFiles.map(uploadDocument));
        };

        function uploadDocument(fileData) {
            var deferred = $q.defer();
            if (fileData.status === 1) {
                var file = fileData.file;
                var docModel = {
                    documentModel: {
                        claimId: _claim.damageClaimId,
                        companyId: authService.authentication.companyId,
                        companyName: authService.authentication.companyName,
                        documentName: fileData.name,
                        documentType: fileData.purpose.value,
                        uploaderId: authService.authentication.userId
                    }
                };
                documentUploadService.uploadDocument(file, docModel).then(function(data) {
                    fileData.url = document.documentUrlRoot + '/documents/' + data.location;
                    fileData.status = 2;
                    deferred.resolve(true);
                }, function(error) {
                    console.log(error);
                    deferred.reject(error);
                });
            } else {
                deferred.resolve(true);
            }
            return deferred.promise;
        };

        function createClaimHeader() {
            return {
                claimDate: _claim.claimDate,
                incidentDate: _claim.incidentDate,
                supplierRef: _claim.supplierRef,
                customerRef: _claim.customerRef,
                brokerRef: _claim.brokerRef,
                damageClaimId: _claim.damageClaimId,
                registration: _claim.registration,
                makeAndModel: _claim.makeAndModel,
                driverName: _claim.driverName,
                supplierId: _claim.supplierId,
                brokerId: _claim.brokerId,
                customerId: _claim.customerId,
                supplierUserId: _claim.supplierUserId,
                brokerUserId: _claim.brokerUserId,
                customerUserId: _claim.customerUserId,
                supplierName: _claim.supplierName,
                brokerName: _claim.brokerName,
                customerName: _claim.customerName,
                status: _claim.status,
                lastAction: _claim.lastAction,
                customerOrderNumber: _claim.customerOrderNumber,
            };
        };

        function updateClaimFromHeader(header) {
            _claim.claimDate = header.claimDate;
            _claim.incidentDate = header.incidentDate;
            _claim.supplierRef = header.supplierRef;
            _claim.customerRef = header.customerRef;
            _claim.brokerRef = header.brokerRef;
            _claim.damageClaimId = header.damageClaimId;
            _claim.registration = header.registration;
            _claim.makeAndModel = header.makeAndModel;
            _claim.driverName = header.driverName;
            _claim.supplierId = header.supplierId;
            _claim.brokerId = header.brokerId;
            _claim.customerId = header.customerId;
            _claim.supplierUserId = header.supplierUserId;
            _claim.brokerUserId = header.brokerUserId;
            _claim.customerUserId = header.customerUserId;
            _claim.supplierName = header.supplierName;
            _claim.brokerName = header.brokerName;
            _claim.customerName = header.customerName;
            _claim.status = 'Draft';
            _claim.lastAction = header.lastAction;
            _claim.customerOrderNumber = header.customerOrderNumber;
        };

        var _customerList = [];


        var _setDocTypesAssigned = function(val) {
            _scopeFlags.docTypesAssigned = val;
        };

        var _setFilesToBeLoaded = function(val) {
            _scopeFlags.filesToBeLoaded = val;
        };

        var _checkList = [
            { itemNo: 0, item: 'Supplier ref.', requirement: 'Optional', present: false },
            { itemNo: 1, item: 'Incident date', requirement: 'Mandatory', present: false },
            { itemNo: 2, item: 'Vehicle registation', requirement: 'Mandatory', present: false },
            { itemNo: 3, item: 'Make and model', requirement: 'Mandatory', present: false },
            { itemNo: 4, item: 'Driver Name', requirement: 'Mandatory', present: false },
            { itemNo: 5, item: 'Value', requirement: 'Optional', present: false },
            { itemNo: 6, item: 'Damage details', requirement: 'If applicable', present: false },
            { itemNo: 7, item: 'Signed delivery and collection sheet', requirement: 'If applicable', present: false },
            { itemNo: 18, item: 'Supplier notification sheet', requirement: 'If applicable', present: false },
            { itemNo: 12, item: 'Rental agreement', requirement: 'If applicable', present: false },
            { itemNo: 13, item: 'Accident report', requirement: 'If applicable', present: false },
            { itemNo: 8, item: 'Images of damage', requirement: 'If applicable', present: false },
            { itemNo: 15, item: 'Assessment', requirement: 'If applicable', present: false },
            { itemNo: 14, item: 'Estimate', requirement: 'If applicable', present: false },
            { itemNo: 9, item: 'Audatex type estimate', requirement: 'If applicable', present: false },
            { itemNo: 10, item: 'Engineer\'s report', requirement: 'If applicable', present: false },
            { itemNo: 16, item: 'Repairer\'s jobsheet', requirement: 'If applicable', present: false },
            { itemNo: 11, item: 'Repairer\'s invoice', requirement: 'If applicable', present: false },
            { itemNo: 17, item: 'Supplier\'s invoice', requirement: 'If applicable', present: false }
        ];

        var _runCheckList = function() {
            var mandated = 0;
            _checkList.forEach(function(item) {
                switch (item.itemNo) {
                case 0:
                    item.present = (_claim.supplierRef !== '' && _claim.supplierRef !== undefined);
                    break;
                case 1:
                    item.present = (_claim.incidentDate !== '' && _claim.incidentDate !== undefined && _claim.incidentDate !== null);
                    break;
                case 2:
                    item.present = (_claim.registration !== '' && _claim.registration !== undefined);
                    break;
                case 3:
                    item.present = (_claim.makeAndModel !== '' && _claim.makeAndModel !== undefined);
                    break;
                case 4:
                    item.present = (_claim.driverName !== '' && _claim.driverName !== undefined);
                    break;
                case 5:
                    item.present = (_claim.value !== '' && _claim.value !== undefined);
                    break;
                case 6:
                    item.present = (_claim.details.length > 0);
                    break;
                case 7:
                    item.present = (_documentFiles.isDocTypePresent(0));
                    break;
                case 8:
                    item.present = (_documentFiles.isDocTypePresent(1));
                    break;
                case 9:
                    item.present = (_documentFiles.isDocTypePresent(2));
                    break;
                case 10:
                    item.present = (_documentFiles.isDocTypePresent(3));
                    break;
                case 11:
                    item.present = (_documentFiles.isDocTypePresent(4));
                    break;
                case 12:
                    item.present = (_documentFiles.isDocTypePresent(10));
                    break;
                case 13:
                    item.present = (_documentFiles.isDocTypePresent(11));
                    break;
                case 14:
                    item.present = (_documentFiles.isDocTypePresent(7));
                    break;
                case 15:
                    item.present = (_documentFiles.isDocTypePresent(9));
                    break;
                case 16:
                    item.present = (_documentFiles.isDocTypePresent(8));
                    break;
                case 17:
                    item.present = (_documentFiles.isDocTypePresent(5));
                    break;
                case 18:
                    item.present = (_documentFiles.isDocTypePresent(12));
                    break;
                default:
                }
                if (item.requirement === 'Mandatory' && item.present) {
                    mandated++;
                }
            });
            _setSubmit(mandated === 4);
            _setInvoice();
        };

        var _setupScope = function (scope) {
            var deferred = $q.defer();
            if (authService.authentication.isAuthBroker && brokerSupportService.requiresRefresh()) {
                brokerSupportService.getCustomerList().then(function (data) {
                    _customerList.length = 0;
                    angular.forEach(data, function(item) {
                        _customerList.push(item);
                    });
                    setScope(scope);
                    deferred.resolve(true);
                }, function(error) {
                    console.log(error);
                    deferred.reject(error);
                });
            } else {
                setScope(scope);
                deferred.resolve(true);
            };
            return deferred.promise;
        }


        var setScope = function (scope) {
            scope.claim = _claim;
            scope.isBroker = authService.authentication.isAuthBroker;
            scope.isSupplier = authService.authentication.isAuthSupplier;
            scope.isCustomer = authService.authentication.isAuthCustomer;
            scope.brokerName = brokerSupportService.brokerName;
            scope.claimStatus = _claimStatus;
            scope.SupInvoiceRequested = _isSettled;
            scope.supplierName = _claim.supplierName;
            scope.supplierEmail = _claim.supplierEmail;
            scope.customerName = _claim.customerName;
            scope.customerEmail = _claim.customerEmail;
            scope.flags = _scopeFlags;
            scope.claimStates = _claimStates;
            scope.documentFiles = _documentFiles;
            scope.brokerName = _claim.brokerName;
            scope.brokerEmail = _claim.brokerEmail;
            scope.brokerNotes = _claim.brokerNotes;
            scope.publicNotes = _claim.publicNotes;
            scope.customerList = _customerList;
            scope.documentTypes = _documentTypes;
            scope.checkList = _checkList;
            scope.canSubmit = _canSubmit;
            scope.canInvoice = _canInvoice;
            scope.docTypes = _docTypes;
            scope.instructionsActive = tabName === 'claimEdit.instructions';
            scope.claimActive = tabName === 'claimEdit.details';
            scope.damageActive = tabName === 'claimEdit.damage';
            scope.documentsActive = tabName === 'claimEdit.documents';
            scope.messagesActive = tabName === 'claimEdit.messages';
            scope.checklistActive = tabName === 'claimEdit.checkList';
            scope.notificationEmailsActive = tabName === 'claimEdit.notificationEmails';
            scope.brokerNotesActive = tabName === 'claimEdit.brokerNotes';
            scope.publicNotesActive = tabName === 'claimEdit.publicNotes';
        };

        var _allowSubmit = false;
        var _allowInvoice = false;

        var _canSubmit = function() {
            return _allowSubmit;
        }

        var _setSubmit = function(val) {
            _allowSubmit = val;
        }
        var _canInvoice = function() {
            return _allowInvoice;
        }

        var _setInvoice = function() {
            _allowInvoice = _documentFiles.isDocTypePresent(5);
        }

        var cesf = {};

        cesf.uploadDocuments = _uploadDocuments;
        cesf.saveClaim = _saveClaim;
        cesf.setClaim = _setClaim;
        cesf.setupScope = _setupScope;
        cesf.setDocTypesAssigned = _setDocTypesAssigned;
        cesf.setFilesToBeLoaded = _setFilesToBeLoaded;
        cesf.claimIsDirty = _claimIsDirty;
        cesf.setClaimDirty = _setClaimDirty;
        cesf.unassignedFiles = _unassignedFiles;
        cesf.broker = authService.authentication.broker;
        cesf.userId = authService.authentication.userId;
        cesf.userEmail = authService.authentication.userEmail;
        cesf.setSubmit = _setSubmit;
        cesf.setInvoice = _setInvoice;
        cesf.runCheckList = _runCheckList;

        return cesf;


    }
]);