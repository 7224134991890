app.controller('claimController', [
    '$scope', 'claimEditService', '$rootScope', function($scope, claimEditService, $rootScope) {


        var getCustomerFromList = function(id) {
            for (var i = 0; i < $scope.customerList.length; i++) {
                if ($scope.customerList[i].id === id) return $scope.customerList[i];
            }
        };

        var getEmailFromList = function (email) {
            console.log('customer for emails:', $scope.customer);
            for (var i = 0; i < $scope.customer.emails.length; i++) {
                console.log('email:', email, 'customer email', $scope.customer.emails[i]);
                if ($scope.customer.emails[i].email === email) return $scope.customer.emails[i];
            }
        };


        $scope.updateCustomer = function () {
            $scope.claim.customerId = $scope.customer.id;
            $scope.claim.customerName = $scope.customer.name;
            if ($scope.customer.emails.length > 0) {
                $scope.claim.customerEmail = $scope.customer.emails[0].email;
                $scope.claim.customerUserId = $scope.customer.emails[0].id;
                $scope.customerEmail = $scope.customer.emails[0];
            }
        };

        $scope.updateCustomerEmail = function () {
            $scope.claim.customerEmail = $scope.customerEmail.email;
            $scope.claim.customerUserId = $scope.customerEmail.id;
            $scope.claim.notifiedInvitedUser = $scope.customerEmail.emailType === 'Invited user';
        }

        $scope.onInputBlur = function() {
            claimEditService.runCheckList();
        }

// DatePicker support

        $rootScope.$on('$stateChangeSuccess', function() {
            $scope.opened = false;
        });

        $scope.opened = false;

        $scope.today = new Date();

        $scope.open = function($event) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened = true;
        };

        $scope.$watchCollection('claim', function() {
            $scope.opened = false;
        }, true);

        $scope.$watch('claimDetails.$dirty', function () {
            claimEditService.setClaimDirty($scope.claimDetails.$dirty);
        });

        claimEditService.runCheckList();
        claimEditService.setupScope($scope).then(function () {
            console.log('cust name', $scope.claim.customerName, 'cust email', $scope.claim.customerEmail);
            if ($scope.isBroker) {
                if ($scope.claim.customerId) {
                    var cust = getCustomerFromList($scope.claim.customerId);
                    $scope.customer = {
                        id: cust.id,
                        name: cust.name,
                        emails: cust.emails,
                    }
                    $scope.customerEmail = getEmailFromList($scope.claim.customerEmail);
                    
                }else {
                    $scope.customer = { id: '', name: '', emails: [] };
                    $scope.customerEmail = { id: '', email: '', emailType: ''};
                }
                console.log('customer from claim', $scope.customer);
                console.log('customer email from claim', $scope.customerEmail);
            } 
        });
    }
]);