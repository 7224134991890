app.controller('claimsController', [
    '$scope',
    '$state',
    'authService',
    'claimsService',
    '$location',
    'claimService',
    'claimList',
    'ngDialog',
    '$q',
    '$interval',
    function($scope, $state, authservice, claimsService, $location, claimService, claimList, ngDialog, $q, $interval) {


        var _refreshClaimsList = function(list) {
            $scope.claims = [];
            console.log('claimList:', list);
            angular.forEach(list, function(item) {
                //item.incidentDate = convertToDate(item.incidentDate);
                //item.lastAction = convertToDate(item.lastAction);
                //item.claimDate = convertToDate(item.claimDate);
                $scope.claims.push(item);
            });
            $scope.claimsCount = $scope.claims.length;
        };

        $scope.companyName = authservice.authentication.companyName;
        $scope.selected = [];

        _refreshClaimsList(claimList);
        $location.search(claimsService.getQueryString());

        //function convertToDate(dateStr) {   // required to get around safari handling of dates
        //    if (!dateStr)return '';
        //    var parts = dateStr.split('T');
        //    var els = parts[0].split('-');
        //    return new Date(els[0], els[1] - 1, els[2]);
        //}

        var autoRefresh = $interval(function() {
            claimsService.getClaims().then(function(data) {
                _refreshClaimsList(data);
            }, function(error) {
                console.log('claims refresh', error);
                stopRefresh();
            });
        }, 300000);

        var stopRefresh = function() {
            if(angular.isDefined(autoRefresh)) {
                $interval.cancel(autoRefresh);
                autoRefresh = undefined;
            }
        }

        $scope.$on('$destroy', function() {
            stopRefresh();
        });

//        $scope.getClaims();
        $scope.locale = 'en-gb';

        $scope.getTable = function() {
            if (authservice.authentication.companyType === "broker") return "app/views/claimTables/brokerTable.html";
            if (authservice.authentication.companyType === "supplier") return "app/views/claimTables/supplierTable.html";
            if (authservice.authentication.companyType === "customer") return "app/views/claimTables/customerTable.html";
            return null;
        }

        $scope.editClaim = function(item) {
            $state.go('claimEdit.details', { id: item.damageClaimId });
        }


        var _confirmDeleteDraft = function() {
            $scope.dialogHeading = 'Confirm Delete Draft';
            $scope.dialogBody = 'Do you want to delete this draft claim?';
            $scope.dialogConfirm = 'Delete';
            $scope.dialogCancel = 'Cancel';
            var deferred = $q.defer();
            ngDialog.openConfirm({
                template: 'app/views/dialogs/confirmDialog.html',
                scope: $scope,
            }).then(function(yes) {
                deferred.resolve(yes);
            }, function(no) {
                deferred.reject(no);
            });
            return deferred.promise;
        };

        var _deleteDraftClaim = function(item) {
            var deferred = $q.defer();
            claimService.removeClaim(item).then(function(data) {
                $scope.claims.splice($scope.claims.indexOf(item), 1);
                deferred.resolve(data);
            }, function(error) {
                deferred.reject(error);
                console.log(error);
            });
        }
        var _confirmArchive = function () {
            $scope.dialogHeading = 'Confirm Archive Claim';
            $scope.dialogBody = 'Do you want to archive this claim?';
            $scope.dialogConfirm = 'Archive';
            $scope.dialogCancel = 'Cancel';
            var deferred = $q.defer();
            ngDialog.openConfirm({
                template: 'app/views/dialogs/confirmDialog.html',
                scope: $scope,
            }).then(function(yes) {
                deferred.resolve(yes);
            }, function(no) {
                deferred.reject(no);
            });
            return deferred.promise;
        };

        var _archiveClaim = function(item) {
            var deferred = $q.defer();
            claimService.archiveClaim(item).then(function(data) {
                $scope.claims.splice($scope.claims.indexOf(item), 1);
                deferred.resolve(data);
            }, function(error) {
                deferred.reject(error);
                console.log(error);
            });
        }

        $scope.deleteClaim = function(item) {
            _confirmDeleteDraft().then(function() { return _deleteDraftClaim(item); }, function() {});
        }

        $scope.archiveClaim = function(item) {
            _confirmArchive().then(function() { return _archiveClaim(item); }, function() {});
        };
    }
]);