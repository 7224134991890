'use strict';
app.controller('homeController', [
    '$state', 'authService', '$scope',
    function($state, authService, $scope) {

        $scope.visible = false;

        if (authService.authentication.isAuth) {
            $state.go('claims');
        } else {
            $scope.visible = true;
        }
    }
]);