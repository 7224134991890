app.factory('customerListService', ['$http', '$q', function ($http, $q) {

    var customerListServiceFactory = {};

    var _getCustomerlist = function() {

        var deferred = $q.defer();
        var url = 'api/customers';

        $http.get(url).success(function(data) {
            deferred.resolve(data);
        }).error(function(error) {
            deferred.reject(error);
        });
        return deferred.promise;
    };

    var _deleteCustomer = function (customer) {
        var url = 'api/customers/' + customer.id;
        var deferred = $q.defer();
        $http.delete(url).success(function (data) {
            deferred.resolve(data);
        }).error(function (error) {
            deferred.reject(error);
        });
        return deferred.promise;
    };

    customerListServiceFactory.getCustomerList = _getCustomerlist;
    customerListServiceFactory.deleteCustomer = _deleteCustomer;

    return customerListServiceFactory;
}])