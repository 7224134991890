app.factory('supplierService', [
    '$http', '$q', function($http, $q) {

        var supplierServiceFactory = {};

        var _newSupplier = {
            id: 0,
            name: '',
            telephone: '',
            registrationId: '',
            users: [],
        }
        _newSupplier.clear = function () {
            this.id = 0;
            this.name = '';
            this.telephone = '';
            this.registrationId = '';
            this.users = [];
            return this;
        };

        var _getSupplier = function(id) {
            var deferred = $q.defer();
            if (id === '0') {
                deferred.resolve(_newSupplier.clear());
                return deferred.promise;
            }

            var url = 'api/suppliers/' + id;

            $http.get(url).success(function(data) {
                deferred.resolve(data);
            }).error(function(error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        var _updateSupplier = function(supplier) {
            var url = 'api/suppliers';
            var deferred = $q.defer();
            if (supplier.id === 0) {
                $http.post(url, supplier).success(function(data) {
                    deferred.resolve(data);
                }).error(function(error) {
                    deferred.reject(error);
                });
            } else {
                url = 'api/suppliers/' + supplier.id;
                $http.put(url, supplier).success(function(data) {
                    deferred.resolve(data);
                }).error(function(error) {
                    deferred.reject(error);
                });
            }
            return deferred.promise;
        };

        var _inviteUser = function(invite) {
            var deferred = $q.defer();
            var url = 'api/users/invite';
            $http.post(url, invite).success(function(data) {
                deferred.resolve(data);
            }).error(function(error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        var _deleteInvitation = function (invitation) {
            var url = 'api/users/invitations/' + invitation.id;
            var deferred = $q.defer();
            $http.delete(url).success(function (data) {
                deferred.resolve(data);
            }).error(function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };
        var _deleteUser = function (user) {
            var url = 'api/users/' + user.id;
            var deferred = $q.defer();
            $http.delete(url).success(function (data) {
                deferred.resolve(data);
            }).error(function (error) {
                console.log('delete user error', error);
                deferred.reject(error);
            });
            return deferred.promise;
        };


        supplierServiceFactory.getSupplier = _getSupplier;
        supplierServiceFactory.updateSupplier = _updateSupplier;
        supplierServiceFactory.inviteUser = _inviteUser;
        supplierServiceFactory.deleteInvitation = _deleteInvitation;
        supplierServiceFactory.deleteUser = _deleteUser;

        return supplierServiceFactory;

    }
]);