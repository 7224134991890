
var app = angular.module('ImsClient', [
    'ui.bootstrap',
    'ui.router',
    'trNgGrid',
    'angularFileUpload',
    'ngDialog',
    'LocalStorageModule',
    'angular-loading-bar',
    'ngAnimate',
    'ui.validate'
]);

app.config([
    '$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {
        $urlRouterProvider.otherwise("/home");

        $stateProvider
            .state("home", {
                url: "/home",
                templateUrl: "app/views/home.html",
                controller: "homeController"
            })
            .state("login", {
                url: "/login",
                templateUrl: "app/views/login.html",
                controller: "loginController"
            })
            .state("signup", {
                url: "/signup/:regId",
                templateUrl: "app/views/signup.html",
                controller: "signupController"
            })
            .state("claims", {
                url: "/claims",
                templateUrl: "app/views/claims.html",
                controller: "claimsController",
                resolve: {
                    claimList: [
                        'claimsService', function(claimsService) {
                            return claimsService.getClaims();
                        }
                    ]
                }
            })
            .state("claimEdit", {
                url: "/claimEdit/:id",
                templateUrl: "app/views/claimEdit.html",
                controller: "claimEditController",
                resolve: {
                    claim: [
                        'claimService', '$stateParams', function(claimService, $stateParams) {
                            return claimService.getClaim($stateParams.id);
                        }
                    ]
                }
            })
            .state("claimEdit.instructions", {
                url: "/instructions",
                templateUrl: "app/views/claimEdit/instructions.html",
                controller: 'instructionsController',
            })
            .state("claimEdit.details", {
                url: "/details",
                controller: "claimController",
                templateUrl: "app/views/claimEdit/details.html",
            })
            .state("claimEdit.damage", {
                url: "/damage",
                controller: "claimDamageController",
                templateUrl: "app/views/claimEdit/damage.html",
            })
            .state("claimEdit.documents", {
                url: "/documents",
                controller: "documentsController",
                templateUrl: "app/views/claimEdit/documents.html",
            })
            .state("claimEdit.messages", {
                url: "/messages",
                controller: "messagesController",
                templateUrl: "app/views/claimEdit/messages.html",
            })
            .state("claimEdit.checkList", {
                url: "/checkList",
                controller: "checkListController",
                templateUrl: "app/views/claimEdit/checkList.html",
            })
            .state("claimEdit.notificationEmails", {
                url: "/notificationEmails",
                controller: "notificationEmailsController",
                templateUrl: "app/views/claimEdit/notificationEmails.html",
            })
            .state("claimEdit.publicNotes", {
                url: "/publicNotes",
                controller: "publicNotesController",
                templateUrl: "app/views/claimEdit/publicNotes.html",
            })
            .state("claimEdit.brokerNotes", {
                url: "/brokerNotes",
                controller: "brokerNotesController",
                templateUrl: "app/views/claimEdit/brokerNotes.html",
            })
            .state("customers", {
                url: "/customers",
                controller: "customerListController",
                templateUrl: "app/views/customers.html",
            })
            .state("suppliers", {
                url: "/suppliers",
                controller: "supplierListController",
                templateUrl: "app/views/suppliers.html",
            })
            .state('customerEdit', {
                url: '/customerEdit/:id',
                controller: 'customerEditController',
                templateUrl: 'app/views/customerEdit.html',
                resolve: {
                    customer: [
                        'customerService', '$stateParams', function(customerService, $stateParams) {
                            return customerService.getCustomer($stateParams.id);
                        }
                    ]
                }
            })
            .state('supplierEdit', {
                url: '/supplierEdit/:id',
                controller: 'supplierEditController',
                templateUrl: 'app/views/supplierEdit.html',
                resolve: {
                    supplier: [
                        'supplierService', '$stateParams', function(supplierService, $stateParams) {
                            return supplierService.getSupplier($stateParams.id);
                        }
                    ]
                }
            })
            .state('userDetailsEdit', {
                url: '/userDetailsEdit',
                controller: 'userDetailsController',
                templateUrl: 'app/views/userDetails.html',
                resolve: {
                    user: [
                        'authService', function(authService) {
                            return authService.getUserDetails();
                        }
                    ]
                }
            })
            .state('errorPage', {
                url: '/errorPage',
                controller: 'errorPageController',
                templateUrl: 'app/views/errorPage.html',
            });
    }
]);

app.config([
    '$httpProvider' , function($httpProvider) {
        var random = Math.floor(Math.random() * 100000);
        $httpProvider.interceptors.push(function() {
            return {
                request: function (config) {
                    if (config.method === 'GET' &&
                        config.url.toLowerCase().indexOf('app/views') === 0 &&
                        config.url.toLowerCase().indexOf('.html') === config.url.length - 5) {
                        config.url = config.url + '?v=' + random;
                    }
                    return config;
                }
            }
        });
        $httpProvider.interceptors.push('identificationService');
    }
]);

app.run([
    function () {
        var enGbTranslation = {};
        enGbTranslation[TrNgGrid.translationDateFormat] = "dd/MM/yyyy";
        TrNgGrid.translations["en-gb"] = enGbTranslation;
    }
]);
app.run([
    '$rootScope', '$state', 'authService', function($rootScope, $state, authService) {
        authService.fillAuthData();

        $rootScope.$on('$stateChangeStart', function (e, toState, toParams, fromState, fromParams) {
            console.log('auth status:', authService.authentication.isAuth);
            console.log('from state:', fromState.name, 'to state:', toState.name);
            if (['login', 'signup'].indexOf(toState.name) === -1) {
                if (!authService.authentication.isAuth) {
                    console.log('redirected to login');
                    e.preventDefault();
                    authService.setDeferredState(toState, toParams);
                    $state.go('login');
                }
            }
        });
    }
]);

//app.config(function ($httpProvider) {
//    $httpProvider.interceptors.push('authInterceptorService');
//});
//
//app.run(['authService', function (authService) {
//    authService.fillAuthData();
//}]);


