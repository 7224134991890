app.factory('brokerSupportService', ['$http','$q', function ($http, $q) {
    var brokerSupportServiceFactory = {};

    var listLoaded = false;
    var customerList;
    var broker = {};

    var getCustomerList = function() {
        var deferred = $q.defer();
        if (!listLoaded) {
            $http.get("api/customers/names")
                .success(function(data) {
                    customerList = data;
                    listLoaded = true;
                    deferred.resolve(customerList);
                }).error(function(error) {
                    deferred.reject(error);
                });
        } else {
            deferred.resolve(customerList);
        }
        return deferred.promise;
    };
    
    var _resetCustomerList = function() {
        listLoaded = false;
        console.log('customer list reset');
    }

    var _requiredRefresh = function() {
        return !listLoaded;
    }

    var _getBroker = function() {
        var deferred = $q.defer();
        var url = 'api/companies/brokers/default';
        $http.get(url).success(function(data) {
            broker = data;
            deferred.resolve(data);
        }).error(function(error) {
            deferred.reject(error);
        });
        return deferred.promise;
    };

    brokerSupportServiceFactory.getCustomerList = getCustomerList;
    brokerSupportServiceFactory.getBroker = _getBroker;
    brokerSupportServiceFactory.resetCustomerList = _resetCustomerList;
    brokerSupportServiceFactory.requiresRefresh = _requiredRefresh;

    return brokerSupportServiceFactory;
}])